<template>
	<div class="container-modal form-modal" id="step2">
        <div v-if="$resize && $mq.above(601)" class="content-icon forgot" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="112" :width="112" v-on:animCreated="handleAnimationLogo"/>
        </div>
		<div class="container-holder">
			<div class="info-content">
				<div class="header-modal">
					<div class="title">{{ $t('subscription.coupon.apply_coupon')}}</div>
				</div>
				<div class="container-content-modal">
					<div class="form-section-register">
						<div class="input-login-group-tbf" v-bind:class="{has_error: errorCoupon}">
							<div class="label-input">
								<label>{{ $t('subscription.coupon.label') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-password /></div>
								<div class="icon-right" v-if="cuponCode != ''" @click.stop="cuponCode = ''"><icon-close /></div>
								<input type="text" v-model="cuponCode" :placeholder="$t('subscription.coupon.coupon-placeholder')" class="input-text">
							</div>
						</div>
					</div>
					<div class="submit-form multiple-btns">
						<button class="btn-tbf grey center" @click="goNextWithout">
							<div class="loader"></div>
							<div class="text">{{$t('subscription.coupon.not-have')}}</div>
						</button>
						<button id="buttonNextStep" class="btn-tbf blue center" @click="checkCode">
							<div class="loader"></div>
							<div class="text">{{$t('subscription.coupon.apply')}}</div>
						</button>
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>


<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconClose from '../Icons/Close'
	import IconPassword from '../Icons/Password'
	
	export default {
		data(){
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true,
				errorCoupon: false,
				cuponCode: ''
			}
		},
		props: {
			user: Object
		},
		components: {
			IconClose,
			IconPassword,
			Lottie
		},
		async mounted() {
			this.logoAnim();
		},
		methods: {
			getUserNewPrices(){
				axios.post('get-referral-coupon', {referral_id : this.user.referral_uuid})
				.then((data) => {
					if(data.data.is_valid){
						this.user.newPrices = data.data.values
						this.user.coupon = data.data.couponCode;
					}
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})				
			},
			goNextWithout(){
				this.user.newPrices = []
				this.user.coupon = null
				if(this.user.referral_uuid){
					this.getUserNewPrices();
				}
				this.$emit("next_step", {user: this.user, next_step: 3});
			},
			checkCode(e){
				var buttonName = 'buttonNextStep'
                var btnSubmit = document.getElementById(buttonName);
                btnSubmit.disabled = true

                var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
                var btnSubmitText = document.querySelector(`#${buttonName} .text`)

                btnSubmit.classList.add('loading')
                btnSubmitLoader.classList.add('onProgress')
                btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.errorCoupon = false
				
				axios.post('validate-coupon', {coupon : this.cuponCode})
				.then((data) => {
					if(data.data.is_valid){
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-success')
								this.user.newPrices = data.data.values
								this.user.coupon = this.cuponCode;
								this.$emit("next_step", {user: this.user, next_step: 3});
							}, 1000)
						}, 300)
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
								this.errorCoupon = true
								this.user.newPrices = []
								this.user.coupon = null;
							}, 1000)
						}, 300)
					}
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
							this.errorCoupon = true
							this.user.newPrices = []
							this.user.coupon = null;
							if(error.response) {
								if(error.response.status == 500) {
									alert(this.$t('error.500'))
								}
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	}
</script>